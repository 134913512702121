import React, { FC, useEffect, useRef, useState } from 'react'
import css from './Slider.module.scss'
import cn from 'classnames'
import Image from '../../../common/Image/Image'
import SwiperCore, { SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Pagination, Autoplay } from 'swiper'
import FsLightbox from 'fslightbox-react'
import { IProductSlide } from '../../../types'

SwiperCore.use([EffectFade, Pagination, Autoplay])

const Slider: FC<{
  slides: IProductSlide[]
  autoplay?: boolean
  className?: string
}> = ({ slides, autoplay = true, className }) => {
  const [swiper, setSwiper] = useState(null)
  const pag = useRef(null)
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const openLightboxOnSlide = (number: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    })
  }

  useEffect(() => {
    if (swiper?.pagination?.bullets?.length > 0) {
      swiper?.pagination?.bullets?.forEach((el, index) => {
        el.addEventListener('mouseover', () => {
          swiper.slideToLoop(index)
        })
      })
    }
  }, [swiper])

  useEffect(() => {
    return () => {
      if (document) {
        document?.documentElement?.classList?.remove('fslightbox-open')
      }
    }
  }, [])

  if (slides?.length === 0) {
    return null
  }

  const options: SwiperOptions = {
    effect: 'fade',
    loop: true,
    autoplay: autoplay
      ? {
          delay: 4000,
        }
      : false,
    pagination: {
      clickable: true,
      bulletClass: css.dot,
      bulletActiveClass: css.dot_active,
      el: pag.current,
      bulletElement: 'div',
    },
  }

  return (
    <div className={cn(css.wrapper, className)}>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={slides.map((slide) => slide.img)}
        slide={lightboxController.slide}
        type="image"
      />
      <Swiper {...options} onInit={(s) => setSwiper(s)}>
        {slides.map((slide, index) => (
          <SwiperSlide
            key={slide.id}
            onClick={() => openLightboxOnSlide(index + 1)}
            className={css.slide}
          >
            <Image
              src={slide.img}
              alt={slide.name}
              layout="responsive"
              width={775}
              height={645}
              objectFit="cover"
              loading={index === 0 ? 'eager' : 'lazy'}
              priority={index === 0}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {slides?.length > 1 && <div className={css.pagination} ref={pag} />}
    </div>
  )
}

export default Slider
